<template>
<div>
  <CaseFormWrapper title="Infos zum Opfer"
                   name="victimForm"
                   :allowedLists="allowedCodelists"
                   :modelName="modelName">
    <label for="address" class="form-label">Adresse</label>
    <input type="url" class="form-control" id="address" placeholder="Str Hausnummer">

  </CaseFormWrapper>

  <div class="text-center border border-4  p-4 mt-5">
    <div class="d-flex justify-content-between">
      <router-link :to="{ name: 'CaseNewCrime' }" class="btn btn-primary">
        <i class="fas fa-arrow-circle-left"></i>
        Zurück zur Tat</router-link>

      <router-link :to="{ name: 'CaseNewPerpetrator' }" class="btn btn-primary">
        Infos zum Täter eintragen
        <i class="fas fa-arrow-circle-right"></i>
      </router-link>
    </div>
  </div>
</div>
</template>
<script>
  import CaseFormWrapper from '@/components/cases/CaseFormWrapper'

export default {
  name: 'CaseVictim',
  components: { CaseFormWrapper },
  data() {
    return {
      modelName: 'victim',
      allowedCodelists: [2, 3, 4, 11]
    }
  },
  methods: {
  }
}
</script>
